<template>
    <div class="gridContainer" id="gridContainer">

        <!-- Edit Window Modal -->
        <div class="editModal" v-if="editWindow.bool">
            <div class="editModalBackground">
                <div class="editModalContent">

                    <div class="editModalTitle">Editing Window 0</div>

                    <b-input-group class="inputGroupHeight" prepend="x">
                        <b-form-select v-model="editWindow.window.left" :options="Array.from(Array(12).keys())"/>
                    </b-input-group>

                    <b-input-group class="inputGroupHeight"  prepend="y">
                        <b-form-select v-model="editWindow.window.top"  :options="Array.from(Array(12).keys())"/>
                    </b-input-group>

                    <b-input-group class="inputGroupHeight"  prepend="height">
                        <b-form-select v-model="editWindow.window.height"  :options="[1,2,3,4,5,6,7,8,9,10,11,12]"/>
                    </b-input-group>

                    <b-input-group class="inputGroupHeight"  prepend="width">
                        <b-form-select v-model="editWindow.window.width"  :options="[1,2,3,4,5,6,7,8,9,10,11,12]"/>
                    </b-input-group>

                    <div class="d-flex w-100 justify-content-between">
                        <b-button size="sm" @click="editWindow.bool = false">
                            Cancel
                        </b-button>
                        <b-button size="sm" @click="submitEditModal" variant="primary">
                            Apply
                        </b-button>
                    </div>

                </div>
            </div>
        </div>

        <!-- Grid Dots -->
        <div class="gridDots" v-for="index in (gridWidth * gridWidth)" :key="'gridDots-' + index">
            <div class="cornerDot" v-for="index in 4" :key="'cornerDot-' + index"/>
        </div>

        <!-- Camera Windows -->
        <div class="windowCard" v-for="(window, index) in gridConfig"
             @drop="dropCamera($event, window._id.toString())" @dragover.prevent :id="getGridDivId(window._id, index)"
             :style="'height: calc(100% / ' + gridWidth + ' * ' + window.height + '); width: calc(100% / ' + gridWidth + ' * '  + window.width + '); top: calc(100% / ' + gridWidth + ' * ' + window.top + ');left: calc(100% / ' + gridWidth + ' * ' + window.left + ')'">
            <h4 v-if="!gridStreams[window._id] || typeof gridStreams[window._id] !== 'object'" style="margin: 0; font-weight: bolder; user-select: none;">{{window._id+1}}</h4>
            <div v-else class="h-100 w-100" style="position: relative"
                 :id="'video-container-' + gridStreams[window._id].ssDevice.getDeviceId() + gridStreams[window._id].sourceToken + gridStreams[window._id].dataChannel">
                <stream :device="gridStreams[window._id].ssDevice" :sourceToken="gridStreams[window._id].sourceToken" :dataChannel="gridStreams[window._id].dataChannel" class="d-flex flex-fill" :isInfinityGrid="currentGridPreset === 'Infinity Grid'"
                        :id="gridStreams[window._id].ssDevice.getDeviceId() + gridStreams[window._id].sourceToken + gridStreams[window._id].dataChannel" :quality="gridStreams[window._id].quality"/>
                <PlayerControls :device="gridStreams[window._id].ssDevice" :isStreamOptions="isStreamOptions" :quality="gridStreams[window._id].quality"
                                class="flex-fill playerControls" :sourceToken="gridStreams[window._id].sourceToken"
                                :dataChannel="gridStreams[window._id].dataChannel" style="position: absolute; z-index: 2" :isGridPreset="true"
                                @openStreamOptions="openStreamOptions(gridStreams[window._id], window._id, 'video-container-' +  gridStreams[window._id].ssDevice.getDeviceId() + gridStreams[window._id].sourceToken + gridStreams[window._id].dataChannel)"
                                @close="closeStream(gridStreams[window._id], window._id)" :showStreamOptions="supportMessageReceived[gridStreams[window._id].ssDevice.getTenantId()] !== undefined"
                                :playback="playback" containerIDPrefix="video-container-"
                                @closeStreamOptions="closeStreamOptions()"/>
            </div>
        </div>

    </div>
</template>

<script>
import PlayerControls from "@/views/video_wall/PlayerControls";
import GridPresets from "@/views/video_wall/GridPresets";
import deviceStore from "@/store/deviceStore";
import PubSub from "pubsub-js";
import stream from "./stream";
import Vue from "vue";

export default {
    name: "GridPresetView",
    components: {
        PlayerControls,
        stream
    },
    props: {
        currentGridPreset: {
            required: true,
            type: String
        },
        playback: {
            type: Boolean,
            default: false
        },
        isStreamOptions: {
            type: Boolean,
            default: true
        },
        layout: {
            type: Object
        },
        supportMessageReceived: {
            type: Object,
            default: {}
        }
    },
    data: () => {
        return {
            gridWidth: 12,
            windowIncrement: 0,
            gridConfig: [],
            editWindow: {
                bool: false,
                window: null
            },
            presetOptions: GridPresets,
            gridStreams: {},
            pubsubs: [],
        }
    },
    created() {
        this.gridConfig = this.presetOptions[this.currentGridPreset];
        this.pubsubs.push(PubSub.subscribe('addActiveCamera', (msg, camera) => {
            for (let i = 0; i < this.gridConfig.length; i++) {
                let id = this.gridConfig[i]._id;
                if (!this.gridStreams[id]) {
                    Vue.set(this.gridStreams, id, camera);
                    break;
                }
            }
        }))
    },
    methods: {
        getGridDivId(id, index) {
            let stream = this.gridStreams[id];
            if (!stream || typeof stream !== 'object') {
                return 'empty-grid-div-' + index;
            }
            return 'grid-div-' + stream.ssDevice.getDeviceId() + stream.sourceToken + stream.dataChannel;
        },
        openStreamOptions(stream, index, containerId) {
            this.$emit('openStreamOptions', {stream: stream, index: index, containerId: containerId});
        },
        closeStreamOptions(stream) {
            this.$emit('closeStreamOptions', stream);
        },
        openEditModal(window) {
            this.gridConfig.bool = true;
            this.gridConfig.window = Object.assign({}, window);
        },
        submitEditModal() {
            let index = this.gridConfig.findIndex(_ => this.editWindow.window._id === _._id);
            if (~index) {
                this.gridConfig[index] = Object.assign(this.gridConfig[index], this.editWindow.window);
            }
        },
        async dropCamera(event, droppedOnIndex) {
            let oldVal, dataChannel;
            try {
                let deviceData = JSON.parse(event.dataTransfer.getData('text'));
                let moveStream = deviceData.moveStream;
                dataChannel = deviceData.dataChannel;
                if (dataChannel === undefined) {
                    return;
                }
                //if we are dragging a stream this is already open then we just move it around in the store
                if (moveStream === true) {
                    let quality = deviceData.quality;
                    let draggedStreamIndex = null;
                    for (const [key, value] of Object.entries(this.gridStreams)) {
                        if (this.gridStreams[key] !== undefined && value.dataChannel === dataChannel) {
                            draggedStreamIndex = key;
                            break;
                        }
                    }
                    if (draggedStreamIndex !== null && draggedStreamIndex !== droppedOnIndex) {
                        if (this.gridStreams[droppedOnIndex] === undefined) {
                            let draggedStream = Object.assign(this.gridStreams[draggedStreamIndex], {quality: quality});
                            await this.closeStream(draggedStream, draggedStreamIndex);
                            await deviceStore.dispatch('addActiveCamera', draggedStream);
                            Vue.set(this.gridStreams, droppedOnIndex, draggedStream);
                        } else {
                            let draggedStream = Object.assign(this.gridStreams[draggedStreamIndex], {quality: quality});
                            let droppedOnStream = this.gridStreams[droppedOnIndex];
                            await this.closeStream(draggedStream, draggedStreamIndex);
                            await this.closeStream(droppedOnStream, droppedOnIndex);
                            await deviceStore.dispatch('addActiveCamera', draggedStream);
                            await deviceStore.dispatch('addActiveCamera', droppedOnStream);
                            Vue.set(this.gridStreams, droppedOnIndex, draggedStream);
                            Vue.set(this.gridStreams, draggedStreamIndex, droppedOnStream);
                        }
                    }
                } else {
                    if (this.gridStreams[droppedOnIndex] && typeof this.gridStreams[droppedOnIndex] === 'object') {
                        let deviceIndex = deviceStore.getters.getCurrentCameras.findIndex(_ => _.dataChannel === this.gridStreams[droppedOnIndex].dataChannel);
                        if (~deviceIndex) {
                            oldVal = Object.assign({}, deviceStore.getters.getCurrentCameras[deviceIndex]);
                        }
                    }
                    PubSub.publish('cameraDrop-' + dataChannel, oldVal);
                    Vue.set(this.gridStreams, droppedOnIndex, dataChannel);
                }
            } catch (e) {}
        },
        closeStream(data, index) {
            deviceStore.dispatch('removeActiveCamera', data);
            Vue.delete(this.gridStreams, index);
        },
        clearCameras() {
            Object.entries(this.gridStreams).forEach(entry => {
                if (entry[1]) {
                    this.closeStream(null, entry[0]);
                }
            })
        },
        async orderStreams(callback) {
            this.gridStreams = await this.layout.devices.reduce((obj, device) => {
                Vue.set(obj, device.order, device.dataChannel);
                return obj;
            }, {});
            if (callback) {
                callback();
            }
        },
        orderCameras() {
            Object.entries(this.gridStreams).forEach(stream => {
                let pos = stream[0];
                let ele = stream[1];
                if (typeof ele === "string") {
                    let index = this.currentCameras.findIndex(_ => _.dataChannel === ele);
                    if (~index) {
                        Vue.set(this.gridStreams, pos, this.currentCameras[index]);
                    }
                } else if (typeof ele === 'object' && ele.dataChannel) {
                    let index2 = this.currentCameras.findIndex(_ => _.dataChannel === ele.dataChannel);
                    if (index2 === -1) {
                        Vue.delete(this.gridStreams, pos);
                    }
                }
            });
        }
    },
    watch: {
        gridStreams() {
            this.$emit('updateGridStreams', this.gridStreams);
        },
        currentGridPreset: function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.gridConfig = this.presetOptions[this.currentGridPreset];
            }
        },
        currentCameras() {
            if (this.currentCameras.length === 0) {
                this.gridStreams = {};
            }
            this.orderCameras();
        },
        layout() {
            if (this.layout) {
                this.clearCameras();
                this.orderStreams(() => {
                    this.orderCameras();
                });
            }
        }
    },
    computed: {
        currentCameras() {
            return deviceStore.getters.getCurrentCameras;
        },
    },
    beforeDestroy() {
        this.pubsubs.forEach(sub => {
            PubSub.unsubscribe(sub);
        });
    }
}
</script>

<style scoped>
.gridContainer {
    background-color: #171717;
    border: 1px solid black;
    display: flex;
    flex-wrap: wrap;
    position: relative;
}
.editModal {
    position: absolute;
    pointer-events: none;
    width: 100%;
    display: flex;
    justify-content: center;
}
.editModalBackground {
    width: 200px;
    pointer-events: all;
    background-color: #1a1a1a;
    border: 2px solid black;
    z-index: 1;
}
.editModalContent {
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 5px
}
.editModalTitle {
    background-color: #131313;
    border: 1px solid black;
    border-radius: 3px;
    font-weight: bolder;
    display: flex;
    justify-content: center;
    font-size: 20px;
}
.inputGroupHeight {
    height: 35px;
}
.gridDots {
    width: calc(100% / 12);
    height: calc(100% / 12);
    position: relative;
    overflow: hidden;
}
.cornerDot {
    background-color: white;
    width: 2.5%;
    aspect-ratio: 1 / 1;
    border-radius: 100%;
    position: absolute;
}
.cornerDot:nth-child(1) {
    top: 0;
    left: 0;
    transform: translate(-50%, -50%);
}
.cornerDot:nth-child(2) {
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
}
.cornerDot:nth-child(3) {
    bottom: 0;
    right: 0;
    transform: translate(50%, 50%);
}
.cornerDot:nth-child(4) {
    bottom: 0;
    left: 0;
    transform: translate(-50%, 50%);
}
.windowCard {
    background-color: black;
    border: 1px solid #0f0c4f;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.playerControls {
    position: absolute;
    z-index: 50;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    display: flex;
}
</style>