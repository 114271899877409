<template>
     <div style="position: relative" @mouseleave="focusInput = false" @mouseenter="focusInput = true" @input="focusInput = true">
         <b-input-group>
             <b-form-input autocomplete="off" @keyup.enter="$emit('displayLayout')"
                           v-model="input"
                           :class="(show && results.length > 0 ? 'mergeInputs ' : '') + (mergeLeft ? 'mergeLeft' : '')"
                           :size="size ? size : 'md'"
                           style="border-right: none; outline-width: 0"
                           @keyup.down="drop()"
                           :style="(height === 0 ? '' : 'height:' +  height + 'px; ') + (width === 0 ? '' : 'width:' +  width + 'px;')"
                           @keyup.enter.native="drop(),focusSelect = false, focusInput = false"/>
             <div class="input-group-append">
                 <span class="input-group-text"
                       style="border-left: none; background-color: #333333; outline-width: 0; padding: 0 5px 0 1px"
                       :style="height === 0 ? '' : 'height:' +  height + 'px;'"
                       :class="(show && results.length > 0 ? 'mergeInputs ' : '') + (mergeRight ? 'mergeRight' : '')">
                     <fa-icon v-if="show && results.length > 0" :icon="['fas', 'caret-up']" style="font-size: 10px; cursor: pointer;"/>
                     <fa-icon v-else-if="arrow" :icon="['fas', 'caret-down']" style="font-size: 10px; cursor: pointer;"/>
                 </span>
             </div>
         </b-input-group>
         <b-form-select class="input"
                        :style="height === 0 ? '' : 'margin-top: ' +  (height/2) + 'px;'"
                        @mouseleave="focusSelect = false"
                        @mouseenter="focusSelect = true"
                        @keyup.enter.native="focusSelect = false, focusInput = false"
                        v-model="output"
                        v-show="show"
                        style="z-index: 500; position: absolute; border-top-left-radius: 0; border-top-right-radius: 0;" :upperCase="true" :arrow="false"
                        :size="size ? size : 'md'"
                        :options="results.length < 1 ? ['no search results'] : results"
                        ref="select" :select-size="results.length > 5 ? 5 : (results.length < 2 ? 2 : results.length)"
                        v-if="results && Array.isArray(results) && results.length > 0" />
     </div>
</template>

<script>
    export default {
        name: "formSelectSearch",
        props: {
            options: {
                type: Array,
                default: new Array
            },
            size: {
                type: String
            },
            userValue: {
                type: Boolean,
                default: true
            },
            arrow: {
                type: Boolean,
                default: true
            },
            upperCase: {
                type: Boolean,
                default: false
            },
            mergeLeft: {
                type: Boolean,
                default: false
            },
            mergeRight: {
                type: Boolean,
                default: false
            },
            value: {
                type: String,
            },
            height: {
                type: Number,
                default: 0
            },
            width: {
                type: Number,
                default: 0
            }
        },
        data: () => {
            return {
                focusInput: false,
                focusSelect: false,
                optionData: [],
                show: false,
                input: '',
                results: [],
                output: '',
                grey: true,
                awaitSearch: false,
                oldInput: ''
            }
        },
        created() {
            this.sortOptions();
        },
        updated() {
        },
        methods: {
            sortOptions() {
                if (this.value) {
                    this.input = this.value
                    if (this.userValue) {
                        this.grey = false;
                    }
                }
                if (this.upperCase) {
                    this.optionData = this.options.map(e => e.text ? e.text.toUpperCase() : e.toUpperCase()).sort((a,b) => {return this.sortify(a,b)});
                } else {
                    this.optionData = this.options.map(e => e.text ? e.text : e).sort((a,b) => {return this.sortify(a,b)});
                }
                if (!this.value) {this.results = this.optionData}
            },
            search(data){
                if(data && Array.isArray(data) && data.length > 0 && this.input.length > 0){
                    let results = [];
                    results = data.filter(e => e.toLowerCase().includes(this.input.toLowerCase()));
                    this.results = this.insertionSort(results);
                }else{
                    this.results = data;
                }
            },
            sortify(a,b){
                if(a.toLowerCase().startsWith(this.input.toLowerCase()) && !b.toLowerCase().startsWith(this.input.toLowerCase())){
                    return  -1;
                }else if (!a.toLowerCase().startsWith(this.input.toLowerCase()) && b.toLowerCase().startsWith(this.input.toLowerCase())){
                    return 1;
                }
                return 0
            },
            drop(){
                if(this.results && this.results[0]){
                    this.$refs.select.focus()
                    this.output = this.results[0]
                    this.$refs.select.$forceUpdate()
                }
            },
            findObj(text){
                let result = this.options.find(i => typeof i === 'object' ? i.text === text || i.value === text : text === i)
                return(result ? result : text)
            },
            insertionSort(arr){
                let length = arr.length;
                for (let i = 1; i < length; i++) {
                    let key = arr[i];
                    let j = i - 1;
                    while (j >= 0 && this.sortify(arr[j], key) > 0) {
                        arr[j + 1] = arr[j];
                        j = j - 1;
                    }
                    arr[j + 1] = key;
                }
                return arr;
            }
        },
        watch: {
            value() {
                this.input = this.findObj(this.value).text ? this.findObj(this.value).text : this.value;
            },
            output() {
                if(this.upperCase)this.input = this.input.toUpperCase()
                this.input = this.findObj(this.output).text ? this.findObj(this.output).text : this.output;
                this.$emit('input', this.output);
            },
            input() {
                this.$forceUpdate();
                if(this.upperCase)this.input = this.input.toUpperCase()
                if(this.input === '' || (this.input !== this.output && this.userValue)){
                    this.$emit('input', this.findObj(this.input).text ? this.findObj(this.input).text : this.input)
                }
                if(!this.awaitSearch){
                    setTimeout(() => {
                        if(this.oldInput.length > this.input.length){
                            let results = this.results
                            this.search(results)
                            this.oldInput = this.input
                        }else{
                            this.search(this.optionData)
                            this.oldInput = this.input
                        }
                    }, 750)
                }
            },
            focusInput() {
                if (this.focusSelect || this.focusInput) {
                    this.show = true
                } else {
                    this.show = false
                }
            },
            focusSelect() {
                if (this.focusSelect || this.focusInput) {
                    this.show = true
                } else {
                    this.show = false
                }
            },
            options() {
                if (this.upperCase === true) {
                    this.optionData = this.options.map(e => e.text ? e.text.toUpperCase() : e.toUpperCase());
                    this.sortOptions();
                } else {
                    this.optionData = this.options.map(e => e.text ? e.text : e);
                    this.sortOptions();
                }
            }
        }
    }
</script>

<style scoped>
    .mergeLeft{
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
    }
    .mergeRight{
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
    }
    .mergeInputs{
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom-width: 0
    }
    :focus{
        box-shadow: none;
        border-color: rgb(35, 40, 44);
    }

    .input-group,
    .b-form-input {
        height: 10px;
    }
</style>

<style>
    input{
        color: red;
    }
</style>